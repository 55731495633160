import './LandingBlogPosts.scss';

import { Button } from '@travelwin/core';
import { useTranslation } from 'react-i18next';

import { BlogPostCardGrid } from '@/components/BlogPostCardGrid';
import { useNavigate } from '@/components/Link';
import { Post } from '@/interfaces/cms';

const className = 'c-LandingBlogPosts';

export const LandingBlogPosts = ({
  posts,
  header,
}: {
  posts: Post[];
  header: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (posts.length === 0) {
    return null;
  }

  const handleNewsClick = () => {
    navigate('/news');
  };

  return (
    <div className={className}>
      <h2 className={`${className}__title`}>{header}</h2>
      <BlogPostCardGrid posts={posts} />
      <div className={`${className}__footer-button`}>
        <Button onClick={() => handleNewsClick()}>
          {t('landing.see_all_posts', 'See All News')}
        </Button>
      </div>
    </div>
  );
};
